import axios from "axios";
import * as yup from "yup";
import { useFormik } from "formik";
import React, { useState } from "react";

import {
  Grid,
  Button,
  Dialog,
  Select,
  MenuItem,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  FormHelperText,
} from "@material-ui/core";

import Loader from "../components/Loader";

export default function DeviceDedicatedIpForm({
  initialData,
  deviceSerial,
  setMessage,
  setError,
  open,
  handleOpen,
  availableIps,
  callRules,
}) {
  const [loading, setLoading] = useState(false);

  const validateIPAddress = (address) => {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        address
      )
    ) {
      return true;
    }

    return false;
  };

  const validationSchema = yup.object({
    inbound_ip: yup
      .string("Inbound IP")
      .required("Inbound IP is required")
      .test("validate-ip", "Invalid IP address", validateIPAddress),
  });

  const createNewRule = (requestData) => {
    setLoading(true);

    axios
      .post(`/device/dedicated_ip/create_rule/${deviceSerial}`, requestData)
      .then((response) => {
        setMessage("Created new rule successfully.");
      })
      .catch((error) => {
        setError(error?.response?.data?.detail || "Error Creating new rule");
      })
      .finally(() => {
        callRules(deviceSerial);
        formik.setSubmitting(false);
        handleOpen(null);
      });
  };

  const formik = useFormik({
    initialValues: initialData || {},
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        if (
          Object.keys(validationSchema.fields).includes(key) &&
          values[key] !== null
        ) {
          obj[key] = values[key];
        }

        return obj;
      }, {});

      createNewRule(requestData);
    },
  });

  if (loading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={() => handleOpen(null)}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">
            Create new Dedicated IP Rule
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl
                  error={Boolean(formik.errors.inbound_ip)}
                  sx={{ width: "100%" }}
                  style={{ minWidth: 180 }}
                >
                  <InputLabel id="inbound_ip">Inbound IP</InputLabel>
                  <Select
                    labelId="inbound_ip"
                    id="inbound_ip"
                    value={formik.values.inbound_ip}
                    placeholder="Inbound IP"
                    onChange={(e) =>
                      formik.setFieldValue("inbound_ip", e.target.value)
                    }
                  >
                    {availableIps.map((ip) => (
                      <MenuItem value={ip} key={ip}>
                        {ip}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(formik.errors.inbound_ip) && (
                    <FormHelperText id="inbound_ip-error-text">
                      {formik.errors.inbound_ip}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={loading || formik.isSubmitting}
              onClick={() => formik.submitForm()}
              variant="contained"
              color="primary"
            >
              Create New Rule
            </Button>
            <Button onClick={() => handleOpen(null)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
