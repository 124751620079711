import React, { useEffect, useState } from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { Alert } from "@material-ui/lab";

import Loader from "./Loader";
import TableHeadCell from "./TableHeadCell";
import DevicePortForwardingForm from "./DevicePortForwardingForm";

const PF_FIELDS = {
  id: "ID",
  inbound_ip: "Inbound IP",
  inbound_port: "Inbound Port",
  internal_ip: "Internal IP",
  internal_port: "Internal Port",
  source_ip: "Source IP",
  protocol: "Protocol",
  application_protocol: "Application Protocol",
  status: "Status",
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "25%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const DevicePortForwarding = (props) => {
  const classes = useStyles();
  const [pfRules, setPfRules] = useState(null);
  const deviceSerial = props.deviceSerial || "UNIT12345";
  const [loading, setLoading] = useState(false);
  const { setError, setMessage } = props;
  const [openForm, setOpenForm] = useState(null);
  const [availableIps, setAvailableIps] = useState([]);

  const getPfRules = (deviceSerial) => {
    setLoading(true);
    axios
      .get(`/device/port_forward/get_rules/${deviceSerial}`)
      .then((response) => {
        setPfRules(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.detail || "Error fetching rules");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteRule = (deviceSerial, ruleId) => {
    setLoading(true);
    axios
      .post(`/device/port_forward/delete_rule/${deviceSerial}/${ruleId}`)
      .then((response) => {
        setMessage("Deleted rule successfully");
        getPfRules(deviceSerial);
      })
      .catch((error) => {
        setError(error?.response?.data?.detail || "Error deleting rule");
      });
  };

  useEffect(() => {
    getPfRules(deviceSerial);

    // eslint-disable-next-line
  }, []);

  const getSummary = (rule) => {
    return (
      <>
        <Typography
          className={classes.heading}
        >{`Inbound: ${rule.inbound_ip}:${rule.inbound_port}`}</Typography>
        <Typography
          className={classes.heading}
        >{`Internal: ${rule.internal_ip}:${rule.inbound_port}`}</Typography>
        <Typography
          className={classes.heading}
        >{`Status: ${rule.status}`}</Typography>
      </>
    );
  };

  useEffect(() => {
    getAvailableIps();
  }, []);

  const getAvailableIps = () => {
    setLoading(true);

    axios
      .get(`/device/port_forward/get_available_ips?ip_type=shared`)
      .then((response) => {
        setAvailableIps(response.data);
      })
      .catch((error) => {
        setError("Error fetching available ips");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) return <Loader />;

  return (
    <>
      <Box>
        <Grid item>
          <Button
            onClick={() => setOpenForm(true)}
            color="primary"
            variant="contained"
            style={{ marginBottom: "5px" }}
          >
            Create New Rule
          </Button>
        </Grid>
        {openForm && (
          <DevicePortForwardingForm
            deviceSerial={deviceSerial}
            setError={setError}
            setMessage={setMessage}
            open={openForm}
            handleOpen={setOpenForm}
            availableIps={availableIps}
            callRules={getPfRules}
          />
        )}
        {pfRules?.rules &&
          pfRules?.rules.map((rule) => (
            <Accordion key={rule.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="content"
                id={rule.id}
              >
                {getSummary(rule)}
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteRule(deviceSerial, rule.id);
                  }}
                  color="secondary"
                  variant="contained"
                  startIcon={<DeleteIcon />}
                >
                  Delete Rule
                </Button>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="PF table">
                    <TableBody>
                      {Object.keys(PF_FIELDS).map((field) => (
                        <TableRow key={`${rule.id}${field}`}>
                          <TableHeadCell>{PF_FIELDS[field]}</TableHeadCell>
                          <TableCell>{rule[field]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
    </>
  );
};

export default DevicePortForwarding;
